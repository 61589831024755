<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="打印机名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="品牌：" prop="brandId">
            <el-radio-group v-model="form.brandId">
              <el-radio v-for="(item, index) in PRINT_BRAND_OPTIONS" :key="index" :label="item.value">{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="打印张数：" prop="number">
            <el-input-number v-model="form.number" :min="1" :max="10000"></el-input-number>
          </el-form-item>
          <el-form-item label="设备号：" prop="deviceId">
            <el-input v-model="form.deviceId"></el-input>
          </el-form-item>
          <el-form-item label="设备秘钥：" prop="deviceSecret">
            <el-input v-model="form.deviceSecret"></el-input>
          </el-form-item>
          <el-form-item label="Gprs卡号：" prop="card">
            <el-input v-model="form.card"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  PRINT_BRAND_OPTIONS,
} from "@/helpers/constants";

import {
  save,
  updateById,
} from "@/apis/uniprint/printer";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      PRINT_BRAND_OPTIONS:PRINT_BRAND_OPTIONS,
      initForm: {
        name: "",
        brandId: "",
        number:"",
        deviceId:"",
        deviceSecret: "",
        card: "",
        status: 0,
        enable: 1
      },
      form: {},
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        brandId: [{ required: true, message: "品牌不能为空", trigger: "blur" }],
        number: [{ required: true, message: "打印张数不能为空", trigger: "blur" }],
        deviceId: [{ required: true, message: "设备号不能为空", trigger: "blur" }],
        deviceSecret: [{ required: true, message: "设备秘钥不能为空", trigger: "blur" }],
        card: [{ required: true, message: "Gprs卡号不能为空", trigger: "blur" }],
      }
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const promise = this.mode === "edit" ? updateById : save;
        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
